export default {
  boldText: {
    fontWeight: 800,
  },
  highlight: {
    fontSize: '1.2rem',
  },
  centerText: {
    textAlign: 'center',
  },
  tint: {
    backgroundColor: '#edf7ff'
  },
  link: {
    color: '#3871c2'
  },
  navLink: {
    textDecoration: 'none'
  },

  headerLine: {
    display: 'inline-block',
    paddingTop: '5px',
    '&::before': {
      content: '""',
      display: 'block',
      width: '50px',
      height: '3px',
      position: 'relative',
      top: '-5px',
      backgroundColor: '#3871c2'
    }
  },
  headerLineH2: {
    display: 'inline-block',
    paddingTop: '5px',
    '&::before': {
      content: '""',
      display: 'block',
      width: '40px',
      height: '3px',
      position: 'relative',
      top: '-5px',
      backgroundColor: '#3871c2'
    }
  },
  headerLineH3: {
    display: 'inline-block',
    paddingTop: '5px',
    '&::before': {
      content: '""',
      display: 'block',
      width: '30px',
      height: '3px',
      position: 'relative',
      top: '-5px',
      backgroundColor: '#3871c2'
    }
  },
  subheader: {
    fontWeight: '500',
    fontSize: '1.5rem',
    color: 'black'
  },
  rounded: {
    overflow: 'hidden',
    borderRadius: '4px',
  },
  justify: {
    textAlign: 'justify'
  },

  listWrapper: {
    '& h5': {
      margin: '0px',
      fontSize: '1rem',
    },
    '& ul': {
      listStyleType: 'none',
      paddingLeft: '25px',
      margin: '10px 0px',
      '& p': {
        margin: '0px'
      },
      '& li': {
        position: 'relative',
        padding: '3px 0px',
        lineHeight: '25px',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '12px',
          left: '-25px',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#3871c2'
        }
      }
    }
  },
  ulist: {
    listStyleType: 'none',
    paddingLeft: '25px',
    '& li': {
      fontWeight: '500',
      fontSize: '1.1rem',
      position: 'relative',
      lineHeight: '35px',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '11px',
        left: '-25px',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: '#3871c2'
      }
    }
  },
  swiperBullets: {
    '& .swiper-pagination-bullet, .swiper-pagination-bullet-active': {
      background: '#3871c2'
    }
  }
}
