import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

const SEO = ({ pageContext }) => {
  const { pathname } = useLocation()

  const pageSEO = pageContext.pageSeo

  const seo = {
    name: pageSEO.name || '',
    title: pageSEO.title || pageContext.seo.title,
    titleTemplate: pageContext.seo.titleTemplate,
    description: pageSEO.description || pageContext.seo.description,
    image: `https:${pageContext.seo.image.file.url}`,
    url: `${pageContext.seo.url}${pathname}`,
  }

  const htmlAttributes = {
    lang: pageContext.lang
  }

  return (
    <Helmet title={seo.title} titleTemplate={(pathname !== '/' && pathname !=='/en' && pathname !=='/en/') ? `${seo.name ? `${seo.name} - ` : ''}${seo.titleTemplate}` : null} htmlAttributes={htmlAttributes}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta name="theme-color" content="#3871c2" />
      <link rel="icon" href="/favicon.ico" />

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {/*(article ? true : null) && <meta property="og:type" content="article" />*/}
    </Helmet>
  )
}

export default SEO
