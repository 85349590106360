import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import {
  ThemeProvider,
  makeStyles,
  styled,
  createMuiTheme
} from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'

import {
  Button as MuiButton,
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  Tabs,
  Tab
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import { parseAlt } from 'src/helpers'

const Button = styled(MuiButton)(spacing)
const Container = styled(MuiContainer)(spacing)

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
    top: '0px',
    position: 'fixed',
    padding: '0px!important',
  },
  appBarBorder: {
    borderBottom: 'solid 1px #e6e6e6'
  },
  navLink: {
    textDecoration: 'none'
  },
  activePage: {
    backgroundColor: '#edf7ff'
  },
  menuIcon: {
    color: 'black',
    '& svg': {
      fontSize: '1.8rem'
    }
  },
  langSwitch: {
    color: 'white',
    display: 'flex',
    fontWeight: '300',
    borderRadius: '40px',
    backgroundColor: '#3871c2',
    '& a': {
      color: 'white',
      display: 'block',
      width: '40px',
      height: '20px',
      textAlign: 'center',
      textDecoration: 'none'
    },
    '& a:hover': {
      fontWeight: '700'
    }
  },
  activeLink: {
    fontWeight: '700'
  },
  mobileNav: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    top: '100vh',
    left: '0',
    height: 'calc(100vh - 94px)',
    width: '100%',
    zIndex: '10000',
    transition: 'top 0.3s',
    backgroundColor: 'white',
    '& button': {
      fontSize: '1.2rem'
    }
  },
  mobileNavOpen: {
    top: '94px'
  }
}))

const languages = ['pl', 'en', 'de']

const toAbsolutePath = (path, pageContext) => `${pageContext.langPrefix}${path}`

const Navigation = ({ pageContext, layout }) => {
  const Navigation = layout
  const classes = useStyles()

  const [mobileNav, setMobileNav] = useState(false)

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav)
  }
  const closeMobileNav = () => setMobileNav(false)

  return (
    <AppBar className={`${classes.appBar} ${pageContext.relativePath !== '/' ? classes.appBarBorder : ''}`} elevation={0}>
      <Toolbar>
        <Box py={2} width={180}>
          <Link to={toAbsolutePath('/', pageContext)}>
            <GatsbyImage image={getImage(Navigation.logo)} alt={parseAlt(Navigation.logo, pageContext)} />
          </Link>
        </Box>
        <Box flexGrow={1}></Box>
        <Hidden mdDown>
          {Navigation.nav.map(item => {
            const absolutePath = item.redirect ?
              `/${item.redirect.metadata.tags.find(x => languages.includes(x.name)).name}${item.redirect.path}`.replace('/pl', '') :
              (item.path.charAt(0) === '#' ?
                toAbsolutePath(`${item.parentPage.path}${item.path}`, pageContext) :
                toAbsolutePath(item.path, pageContext)
              )

            return item.path.charAt(0) === '#' ? (
              <AnchorLink className={classes.navLink} to={absolutePath} title={item.name} key={item.id}>
                <Button mr={2} disableRipple className={pageContext.pagePath === absolutePath ? classes.activePage : ''}>{item.name}</Button>
              </AnchorLink>
            ) : (
              <Link className={classes.navLink} to={absolutePath} key={item.id}>
                <Button mr={2} disableRipple className={pageContext.pagePath === absolutePath ? classes.activePage : ''}>{item.name}</Button>
              </Link>
            )
          })}
          <Box className={classes.langSwitch} ml={2} px={1} py={1}>
            <Link className={pageContext.lang === 'pl' ? classes.activeLink : ''} to={pageContext.redirectPath}>PL</Link><span>/</span>
            <Link className={pageContext.lang === 'en' ? classes.activeLink : ''} to={`/en${pageContext.redirectPath}`}>EN</Link>
            {/*<Link className={pageContext.lang === 'de' ? classes.activeLink : ''} to={`/de${pageContext.redirectPath}`}>DE</Link>*/}
          </Box>
        </Hidden>
        <Hidden lgUp>
          <IconButton edge="end" className={classes.menuIcon} onClick={toggleMobileNav} aria-label="menu">
            {mobileNav ? (<CloseIcon />) : (<MenuIcon />)}
          </IconButton>
          <div className={`${classes.mobileNav} ${mobileNav ? classes.mobileNavOpen : ''}`}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {Navigation.nav.map(item => item.path.charAt(0) === '#' ? (
                <AnchorLink className={classes.navLink} to={toAbsolutePath(`${item.parentPage.path}${item.path}`, pageContext)} title={item.name} key={item.id}>
                  <Button disableRipple onClick={closeMobileNav} mb={1}>{item.name}</Button>
                </AnchorLink>
              ) : (
                <Link className={classes.navLink} to={toAbsolutePath(item.path, pageContext)} key={item.id}>
                  <Button disableRipple onClick={closeMobileNav} mb={1}>{item.name}</Button>
                </Link>
              ))}
            </Box>
            <Box className={classes.langSwitch} px={1} py={1}>
              <Link className={pageContext.lang === 'pl' ? classes.activeLink : ''} to={pageContext.redirectPath}>PL</Link><span>/</span>
              <Link className={pageContext.lang === 'en' ? classes.activeLink : ''} to={`/en${pageContext.redirectPath}`}>EN</Link>
              {/*<Link className={pageContext.lang === 'de' ? classes.activeLink : ''} to={`/de${pageContext.redirectPath}`}>DE</Link>*/}
            </Box>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
