import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import {
  ThemeProvider,
  makeStyles,
  styled,
  createMuiTheme
} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { spacing } from '@material-ui/system'

import {
  Button as MuiButton,
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  Tabs,
  Tab
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

import {
  Menu as MenuIcon,
  Group as GroupIcon,
  MonetizationOn as MonetizationOnIcon,
  LocalShipping as LocalShippingIcon,
  Eco as EcoIcon,
  Book as BookIcon,
  Phone as PhoneIcon,
  Cloud as CloudIcon,
} from '@material-ui/icons'
import { parseAlt } from 'src/helpers'

const Button = styled(MuiButton)(spacing)
const Container = styled(MuiContainer)(spacing)

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: '#3871c2',
    color: 'white',
    '& a': {
      fontWeight: '700',
      color: 'white'
    }
  },
  pfrImg: {
    borderRadius: '4px'
  },
  socialLink: {
    color: 'white',
    margin: theme.spacing(1.5),
    '& svg': {
      fontSize: '1.6rem'
    },
    '&:hover' : {
      color: '#e6e6e6'
    }
  },
  createdBy: {
    margin: '0px',
    paddingTop: theme.spacing(3)
  },
  privacyPolicy: {
    margin: '0px',
    paddingTop: theme.spacing(3)
  },
  copy: {
    margin: '0px',
    paddingTop: theme.spacing(2)
  },
  [theme.breakpoints.up('md')]: {
    pfr: {
      position: 'absolute',
      right: '30px'
    }
  }
}))

const Footer = ({ pageContext, layout }) => {
  const Layout = layout
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))

  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.footer} display="flex" flexDirection="column" alignItems="center" py={5} mt={0}>
        <Box className={classes.pfr} pb={bptMd ? 0 : 6}>
          <GatsbyImage className={classes.pfrImg} image={getImage(Layout.footerPfr)} />
        </Box>
        <Box width="160px">
          <GatsbyImage image={getImage(Layout.footerLogo)} alt={parseAlt(Layout.footerLogo, pageContext)} />
        </Box>
        <Box pt={5} pb={1}>
          <a className={classes.socialLink} href="https://www.facebook.com/BamaLogistics" target="_blank">
            <FacebookIcon />
          </a>
          <a className={classes.socialLink} href="https://www.instagram.com/bamalogistics/" target="_blank">
            <InstagramIcon />
          </a>
          <a className={classes.socialLink} href="https://www.linkedin.com/company/bamalogistics/" target="_blank">
            <LinkedInIcon />
          </a>
        </Box>
        <p className={classes.privacyPolicy}><Link to={`${pageContext.langPrefix}/${pageContext.lang === 'pl' ? 'polityka-prywatnosci' : 'privacy-policy'}`}>
          {pageContext.lang === 'pl' ? 'Polityka Prywatności' : 'Privacy Policy'}
        </Link></p>
        <p className={classes.copy}>&copy; Bama Logistics, 2021</p>
      </Box>
    </Box>
  )
}

export default Footer
