import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'

import {
  Box
} from '@material-ui/core'

import SEO from 'src/components/seo'
import Navigation from 'src/components/navigation'
import Footer from 'src/components/footer'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3871c2'
    },
    secondary: {
      main: '#ffffff'
    }
  }
})

// theme.typography.h3 = {
//   fontSize: '3rem',
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '2.2rem'
//   }
// }
// theme.typography.h4 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.7rem'
//   }
// }

const useStyles = makeStyles(theme => ({
  layoutWrapper: {
    paddingTop: '94px'
  }
}))

const Layout = ({ children, pageContext }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allContentfulNavigation {
        edges {
          layout: node {
            logo {
              gatsbyImageData(layout: CONSTRAINED)
              description
            }
            nav {
              id
              path
              name
              parentPage {
                path
                name
              }
              redirect {
                path
                parentPage {
                  path
                }
                metadata {
                  tags {
                    name
                  }
                }
              }
            }
            metadata {
              tags {
                name
              }
            }
            footerLogo {
              gatsbyImageData(layout: CONSTRAINED)
              description
            }
            footerPfr {
              gatsbyImageData(width: 250)
              description
            }
          }
        }
      }
    }
  `)
  const layout = data.allContentfulNavigation.edges.find(
    ({ layout }) => layout.metadata.tags[0].name === pageContext.lang
  ).layout

  return (
    <ThemeProvider theme={theme}>
      <SEO pageContext={pageContext} />
      <Navigation pageContext={pageContext} layout={layout} />
      <div className={classes.layoutWrapper}>
        {children}
      </div>
      <Footer pageContext={pageContext} layout={layout} />
      <CookieConsent
        location="bottom"
        buttonText={pageContext.lang === 'pl' ? 'Wyrażam zgodę' : 'Accept'}
        cookieName="cookie-consent"
        style={{ background: "#e6e6e6", color: '#000000' }}
        buttonStyle={{ color: "#ffffff", padding: '10px', borderRadius: '4px', background: '#3871c2', fontSize: "13px" }}
        expires={150}
        onAccept={(acceptedByScrolling) => {
          Cookies.set('gatsby-gdpr-google-tagmanager', true, { expires: 150 })
          Cookies.set('gatsby-gdpr-facebook-pixel', true, { expires: 150 })
          Cookies.set('gatsby-gdpr-hotjar', true, { expires: 150 })
          Cookies.set('cookie-consent', true, { expires: 150 })
        }}
      >
        {
          pageContext.lang === 'pl'
            ? 'Używamy pliki cookies, wtyczki społecznościowe i inne podobne technologie do zbierania danych, by analizować statystyki ruchu na stronie.'
            : 'We use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic, and to understand where our visitors are coming from.'
        }
      </CookieConsent>
    </ThemeProvider>
  )
}

export default Layout
